import React from "react"
import { useStaticQuery, graphql } from "gatsby";
import { Box, Text } from "@chakra-ui/react"
import Layout from "../components/Layout";
import CustomContainer from "../components/UI/CustomContainer"
import CustomLink from "../components/UI/CustomLink"

const Privacy = props => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(filter: { frontmatter: { settingName: { in: ["legals", "siteinfos"] } } }) {
          nodes {
            frontmatter {
              legalName
              legalDirector
              legalAddress
              legalPhone
              legalEmail
              legalSIREN
              legalTVA
              legalCompany {
                legalStatus
                legalCapital
              }
              legalCopyright
              siteURL
            }
          }          
        }
      }
    `
  );
  const data0 = allMarkdownRemark.nodes[0].frontmatter || {}
  const data1 = allMarkdownRemark.nodes[1].frontmatter || {}
  const empty = '"En cours de rédaction"'
  const siteURL = data0.siteURL === null ? data1.siteURL === null ? empty : data1.siteURL : data0.siteURL
  const legalName = data0.legalName === null ? data1.legalName === null ? empty : data1.legalName : data0.legalName
  const legalDirector = data0.legalDirector === null ? data1.legalDirector === null ? empty : data1.legalDirector : data0.legalDirector
  const legalAddress = data0.legalAddress === null ? data1.legalAddress === null ? empty : data1.legalAddress : data0.legalAddress
  const legalPhone = data0.legalPhone === null ? data1.legalPhone === null ? empty : data1.legalPhone : data0.legalPhone
  const legalEmail = data0.legalEmail === null ? data1.legalEmail === null ? empty : data1.legalEmail : data0.legalEmail
  return (
    <Layout title="Politique de confidentialité">
      <CustomContainer
      py={[10,16,20]}
      sx={{
        a: {
          textDecoration: "underline"
        }
      }}>
        <Box>
          <Text as="h2" mt="0">Introduction</Text>
          <Text>Dans le cadre de son activité, la société {legalName ? legalName : legalDirector }, dont le siège social est situé au {legalAddress}, est amenée à collecter et à traiter des informations dont certaines sont qualifiées de "données personnelles". {legalName ? legalName : legalDirector } attache une grande importance au respect de la vie privée, et n’utilise que des donnes de manière responsable et confidentielle et dans une finalité précise.</Text>
          <Text as="h2">Données personnelles</Text>
          <Text>Sur le site web {siteURL}, il y a 2 types de données susceptibles d’être recueillies :</Text>
          <ul>
            <li>
              <Text><strong>Les données transmises directement</strong><br />
              Ces données sont celles que vous nous transmettez directement, via un formulaire de contact ou bien par contact direct par email. Sont obligatoires dans le formulaire de contact le champs « prénom et nom » et « email ».</Text>
            </li>
            <li>
              <Text><strong>Les données collectées automatiquement</strong><br />
              Lors de vos visites, une fois votre consentement donné, nous pouvons recueillir des informations de type « web analytics » relatives à votre navigation, la durée de votre consultation, votre adresse IP, votre type et version de navigateur. La technologie utilisée est le cookie.</Text>
            </li>
          </ul>
          <Text as="h2">Utilisation des données</Text>
          <Text>Les données que vous nous transmettez directement sont utilisées dans le but de vous re-contacter et/ou dans le cadre de la demande que vous nous faites. Les données « web analytics » sont collectées de forme anonyme (en enregistrant des adresses IP anonymes) par Google Analytics, et nous permettent de mesurer l'audience de notre site web, les consultations et les éventuelles erreurs afin d’améliorer constamment l’expérience des utilisateurs. Ces données sont utilisées par {legalName ? legalName : legalDirector }, responsable du traitement des données, et ne seront jamais cédées à un tiers ni utilisées à d’autres fins que celles détaillées ci-dessus.</Text>
          <Text as="h2">Base légale</Text>
          <Text>Les données personnelles ne sont collectées qu’après consentement obligatoire de l’utilisateur. Ce consentement est valablement recueilli (boutons et cases à cocher), libre, clair et sans équivoque.</Text>
          <Text as="h2">Durée de conservation</Text>
          <Text>Les données seront sauvegardées durant une durée maximale de 13 mois.</Text>

          <Text as="h2">Cookies</Text>
          <Text>Selon <a href="https://fr.wikipedia.org/wiki/Cookie_(informatique)" target="_blank" rel="noreferrer">Wikipedia</a>, un cookie (ou traceurs en français) est l’équivalent d’un fichier texte de petite taille, stocké sur le terminal de l’internaute. Existant depuis les années 1990, ils permettent aux développeurs de sites web de conserver des données utilisateur afin de faciliter la navigation et de permettre certaines fonctionnalités.</Text>
          <Text>Les cookies permettent de stocker, durant la durée indiquée par son émetteur, des informations d’état lorsqu’un navigateur Internet accède aux différentes pages d’un site web ou lorsque ce navigateur retourne ultérieurement sur ce site web.</Text>
          <Text>Il existe différents types de cookies :</Text>
          <ul>
            <li>des cookies de session qui disparaissent dès que vous quittez le site,</li>
           <li>des cookies permanents qui demeurent sur votre terminal jusqu’à expiration de leur durée de vie ou jusqu’à ce que vous les supprimiez à l’aide des fonctionnalités de votre navigateur.</li>
          </ul>
          <Text>Voici la liste des cookies utilisées sur notre site et leur objectif :</Text>
          <ul>
            <li>Cookies Google Analytics (<CustomLink href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">liste exhaustive</CustomLink>) : Web analytics</li>
            <li>Cookie permettant de garder en mémoire le fait que vous acceptez les cookies afin de ne plus vous importuner lors de votre prochaine visite.</li>
          </ul>

          <Text as="h2">Consentement</Text>
          <Text>En accord avec <a href="https://www.cnil.fr/fr/en-savoir-plus/textes-fondateurs/loi78-17/#_blank" target="_blank" rel="noreferrer">l’article 32-II de la loi du 6 janvier 1978</a>, à la première connexion sur notre site Internet, nous vous informons, par un bandeau explicatif, le dépôt et la lecture de cookies sur votre navigateur.</Text>

          <Text as="h2">Gérer / refuser l’utilisation des cookies</Text>
          <Text>Les navigateurs acceptent par défaut l’utilisation des cookies. Si vous ne souhaitez pas que des cookies soient déposés sur votre navigateur, vous avez la possibilité d’effectuer un paramétrage, comme détaillé ci-après.</Text>
          <Text>Vous avez également la possibilité de paramétrer votre navigateur afin d’accepter ou refuser au cas par cas l’utilisation des cookies. Tous les cookies de votre navigateur sont accessibles via les réglages. Vous pourrez ainsi, si vous le souhaitez, les supprimer.</Text>
          <Text>Chaque navigateur dispose d’une configuration spécifique dans la gestion des cookies. En effectuant une recherche via votre moteur de recherche préféré, en indiquant par exemple « comment régler les cookies » + le nom de votre navigateur, vous trouverez la procédure adéquate. À titre d’exemple :</Text>
          <ul>
            <li>pour <a href="https://support.google.com/chrome/answer/95647" target="_blank" rel="noreferrer">Google Chrome</a>,</li>
            <li>pour <a href="https://privacy.microsoft.com/fr-fr/windows-10-microsoft-edge-and-privacy" target="_blank" rel="noreferrer">Microsoft Edge</a>,</li>
            <li>pour <a href="https://support.apple.com/kb/ph31411?locale=fr_CA" target="_blank" rel="noreferrer">Safari</a>,</li>
            <li>pour <a href="https://support.mozilla.org/fr/kb/empecher-sites-enregistrer-preferences" target="_blank" rel="noreferrer">Mozilla Firefox</a>,</li>
            <li>pour <a href="http://help.opera.com/Windows/10.20/fr/cookies.html" target="_blank" rel="noreferrer">Opera</a>.</li>
          </ul>
          <Text>Ce paramétrage s’effectue navigateur par navigateur. Donc si vous utilisez plusieurs équipements informatiques (par exemple un ordinateur fixe, un téléphone mobile et une tablette) vous devrez effectuer votre paramétrage sur chacun des équipements.</Text>

          <Text as="h2">Vos droits concernant les données personnelles</Text>
          <Text>Vous avez le droit de consultation, demande de modification ou d’effacement sur l’ensemble de vos données personnelles. Vous pouvez également retirer votre consentement au traitement de vos données.</Text>
          <Text as="h2">Contact délégué à la protection des données</Text>
          {legalDirector && (
            <Text>{legalDirector}</Text>
          )}
          {legalPhone && (
            <Text>Téléphone : {legalPhone}</Text>
          )}
          {legalEmail && (
            <Text>Email : {legalEmail}</Text>
          )}
        </Box>
      </CustomContainer>
    </Layout>
  );
};

export default Privacy
